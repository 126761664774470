import React from "react";
import FeatureCard from "../../../common/feature-card/FeatureCard";
import { useTranslation } from "react-i18next";
import { svgs } from "../../../../core/constants/svgs";

const CategoryLengthTypes = ({ data, img, onLookupClick, style, isVisible = true }) => {
  const { t } = useTranslation();
  const key = "category_length_types";
  
  return (
    <div style={isVisible ? {height: 'auto', visibility: 'visible'} : {height: '0px', visibility: 'hidden'}}>
      {data?.[key]?.length > 0 && (
        <div id={key + "-title-id"}>
          <div className="row justify-content-center mt-3">
            <div className="col-lg-4 col-md-6 mb-3">
              <h4 className="section-desc">{t("how_long_is_your_story")}?</h4>
            </div>
            <div className="col-lg-4 col-md-6" />
          </div>
          <div className="row justify-content-center">
            {data?.[key]?.map((item, index) => (
              <FeatureCard
                key={key + "-" + index}
                onClick={onLookupClick(key, item)}
                img={
                  index === 0
                    ? svgs.gripHorizontalTwoLines
                    : index === 1
                    ? svgs.gripHorizontalThreeLines
                    : svgs.gripForizontalFourLines
                }
                name={item?.name}
                description={item?.description}
                is_selected={item?.is_selected}
              />
            ))}
          </div>
        </div>
      )}
    </ div>
  );
};

export default CategoryLengthTypes;
