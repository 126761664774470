import React from "react";
import "./FeatureCard.css";

function FeatureCard({ img, name, description, is_selected, onClick }) {
  if(!name) return
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div
        className={
          "aai-feature-box " + (is_selected ? "aai-feature-box_selected" : "")
        }
        onClick={onClick}
      >
        <div className="aai-feature-box-inner d-flex flex-column align-items-center">
          <div>
            <img src={img} className="aai-feature-icon " alt="" />
          </div>
          <h3 className="aai-feature-title">{name}</h3>
          <p className="aai-feature-desc">{description}</p>
          <div className="aai-feature-link">
            <svg
              width="37"
              height="13"
              viewBox="0 0 37 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.375 1L35.055 6.27428L29.375 11.9543"
                stroke="currentColor"
                strokeOpacity="0.80"
                strokeWidth="1.52"
                strokeLinecap="round"
              />
              <path
                d="M34 6.25L1 6.25"
                stroke="currentColor"
                strokeOpacity="0.80"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureCard;
