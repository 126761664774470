import React from "react";
import { LoginBox } from "./LoginBox";

const Login = () => {
  return (
    <section className="aai-signup-in">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-11 col-md-10 col-lg-9 col-xl-7">
            <LoginBox/>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
