import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ onVerify }) => {
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (onVerify) {
      onVerify(token); // Pass the token back to the parent component
    }
  };

  const handleCaptchaExpired = () => {
    setCaptchaToken(null);
    if (onVerify) {
      onVerify(null); // Notify parent component that the token has expired
    }
  };

  // NOTE: refactor this into .env file
  const RECAPTCHA_SITE_KEY = "6LckGIQqAAAAAFc3O-VjJkiQxCXffAZBEy6W5i_r";

  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleCaptchaChange}
      onExpired={handleCaptchaExpired}
    />
  );
};

export default Captcha;
