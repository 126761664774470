import React from "react";
import "./Modal.css";
import bg from "../../../assets/img/bg/aai-feature-bg.jpeg";

const Modal = ({ id, children }) => {
  window.onclick = function (event) {
    const modal = document.getElementById(id);
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    const modal = document.getElementById(id);
    modal.style.display = "none";
  };

  return (
    <div id={id} className="custom-modal" style={{ display: "none" }}>
      <div className="custom-modal-content">
        <span
          id={"close-" + id}
          className="custom-modal-close"
          onClick={onCloseClick}
        >
          &times;
        </span>
        <section
          className="aai-features py-100"
          // style={{ background: `url(${bg}) no-repeat center center/cover` }}
        >
          {children}
        </section>
      </div>
    </div>
  );
};

export default Modal;
