import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUser, postUpSertUser } from "../../core/api/Api";

const Profile = () => {
  const { t } = useTranslation();
  const mounted = useRef(true);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    const obj = {
      id: userDetails?.id,
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      city: userDetails?.city,
    };
    setLoading(true);
    postUpSertUser(obj)
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setUserDetails(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getUserDetails = () => {
    setLoading(true);
    getUser()
      .then((res) => {
        setLoading(false);
        if (mounted.current && res?.data?.success) {
          const data = res.data.success;
          setUserDetails(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section
      className="aai-signup-in"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      {userDetails?.email && (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2 col-lg-4 d-none d-xl-block order-2 order-lg-1">
              <div className="position-relative">
                {/* <img src={img} className="img-fluid aai-signup-in-img" alt="" /> */}
              </div>
            </div>
            <div className="col-xl-8 order-1 ps-xl-5 order-lg-2 mb-5 mb-lg-0">
              <div className="aai-form-wrapper">
                <div className="">
                  <div className="aai-form-header d-flex justify-content-center text-center flex-column align-items-center mb-3">
                    <h2 className="aai-form-title">{t("edit_your_info")}</h2>
                  </div>
                </div>
                <div>
                  <div>
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="aai-form-container">
                          <input
                            className="form-control shadow-none"
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder={t("enter_your_first_name")}
                            value={userDetails?.first_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="aai-form-container">
                          <input
                            className="form-control shadow-none"
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder={t("enter_your_last_name")}
                            value={userDetails?.last_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="aai-form-container">
                          <input
                            className="form-control shadow-none"
                            id="email"
                            name="email"
                            type="email"
                            value={userDetails?.email}
                            placeholder={t("enter_your_email")}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="aai-form-container">
                          <input
                            className="form-control shadow-none"
                            id="city"
                            name="city"
                            type="text"
                            placeholder={t("enter_your_city")}
                            value={userDetails?.city}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button
                            onClick={handleSubmit}
                            type="submit"
                            disabled={loading}
                            className="aai-btn btn-pill-solid"
                          >
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Profile;
