import axios from "axios";
import { getBrowserLanguage, getCookie } from "../common-service/CommonService";

export const BASE_URL = window.location.host.startsWith("localhost")
  ? "http://localhost:8000/"
  : window.location.host.startsWith("dev-")
  ? "https://dev-api.talegacyremembered.com/"
  : "https://api.talegacyremembered.com/";
export const PAYMENT_GATEWAY_PUBLIC_KEY =
  window.location.host.startsWith("localhost") ||
  window.location.host.startsWith("dev-")
    ? process.env.REACT_APP_PAYMENT_GATEWAY_PUBLIC_KEY_DEV
    : process.env.REACT_APP_PAYMENT_GATEWAY_PUBLIC_KEY_PROD;
export const MEDIA_URL = BASE_URL.slice(0, -1);

axios.defaults.baseURL = BASE_URL + "api/";
axios.defaults.timeout = 3600 * 1000;
axios.defaults.headers.common["lng"] =
  getCookie("lng") ?? getBrowserLanguage() ?? "en";

export function setDefaultHeader(value) {
  axios.defaults.headers.common["Authorization"] = value;
}

export function postGenerateBook(data) {
  return axios.post("postGenerateBook", data);
}

// Auth
export function postRefreshToken() {
  return axios.post("refreshToken");
}
export function postRevokeToken() {
  return axios.post("revokeToken");
}
export function getUser() {
  return axios.get("getUser");
}
export function postUpSertUser(data) {
  return axios.post("postUpSertUser", data);
}
export function postLogin(data) {
  return axios.post("login", data);
}
export function postRegister(data) {
  return axios.post("register", data);
}
export function postVerifyRegistration(data) {
  return axios.post("verifyRegistration", data);
}
export function postVerifyRegistrationWithoutOtp(data) {
  return axios.post("verifyRegistrationWithoutOtp", data);
}
export function postForgotPassword(data) {
  return axios.post("forgotPassword", data);
}
export function postResetPassword(data) {
  return axios.post("resetPassword", data);
}

export function getAllServices() {
  return axios.get("getAllServices");
}
export function getGeneralCategoriesById(id) {
  return axios.get("getGeneralCategoriesById/" + id);
}
export function getGeneralCategoryLookupsById(id) {
  return axios.get("getGeneralCategoryLookupsById/" + id);
}

export function postGetQuestions(data, isLoggedIn = false) {
  return axios.post("getQuestions" + (isLoggedIn ? "" : "AsGuest"), data);
}

export function postGetGenerateCategoriesWithQuestions(
  data,
  isLoggedIn = false
) {
  return axios.post(
    "getGenerateCategoriesWithQuestions" + (isLoggedIn ? "" : "AsGuest"),
    data
  );
}

export function getTotalUsersCount() {
  return axios.get("getTotalUsersCount");
}

export function getSession(id) {
  return axios.get("getSession/" + id);
}

export function getUserSessions() {
  return axios.get("getUserSessions");
}

export function upSertSession(data) {
  return axios.post("upSertSession", data);
}

export function deleteSessionById(data) {
  return axios.post("deleteSessionById", data);
}

export function postCreatePaymentForm(data) {
  return axios.post("createPaymentForm", data);
}
export function postGetPaymentForm(data) {
  return axios.post("getPaymentForm", data);
}
export function postCreateGiftCardPaymentForm(data) {
  return axios.post("createGiftCardPaymentForm", data);
}
export function getOnPaymentCallback(service_session_id) {
  return axios.get("onPaymentCallback/" + service_session_id);
}
export function getOnGiftCardPaymentCallback(plan_id, order_id, email) {
  return axios.get(`onGiftCardPaymentCallback/${plan_id}/${order_id}/${email}`);
}

// Plans
export function getPlansForGift() {
  return axios.get("getPlansForGift");
}

// Coupon
export function postCheckCouponForPayment(data) {
  return axios.post("checkCouponForPayment", data);
}

// Chat-GPT
export function postGetStoryAnswer(data) {
  return axios.post("getStoryAnswer", data);
}

export function postGetOptimizedTranscript(data) {
  return axios.post("getOptimizedTranscript", data);
}

// Google Cloud Speech To Text
export async function postGetGoogleSpeechToText(data) {
  return axios.post("getGoogleSpeechToText", data);
}
