import React from "react";
import { useTranslation } from "react-i18next";

const Option = ({ title }) => {
  return (
    <li className="aai-price-list-item d-flex align-items-center">
      <i className="fa-regular fa-circle-check"></i>
      <span className="aai-price-list-item-text">{title}</span>
    </li>
  );
};

function PlanCard({ item, onButtonClick }) {
  const { t } = useTranslation();

  return (
    <div
      className="aai-price-table mb-4"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <div className="row g-4 align-items-center justify-content-between">
        <div className="col-xl-4 col-lg-6">
          <div className="aai-price-pack">
            <h3 className="aai-price-pack-name">{item?.name ?? ""}</h3>
            {item?.description && (
              <p className="aai-price-pack-desc">{item?.description ?? ""}</p>
            )}
          </div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <ul className="aai-price-lists list-unstyled">
            {item?.plan_options?.map((el, i) => (
              <Option key={"plan_option_" + i} title={el?.name ?? ""} />
            ))}
          </ul>
        </div>
        <div className="col-xl-3 col-lg-6">
          <div className="aai-price-value">
            <h2 className="aai-price">
              {(item?.price ?? "") + " " + (item?.currency?.name ?? "")}
              {item?.price_description && (
                <p className="section-desc">{item?.price_description ?? ""}</p>
              )}
            </h2>
          </div>
        </div>
        <div className="col-xl-2 col-lg-6">
          <div>
            <button
              onClick={onButtonClick}
              className="aai-gradient-outline-btn"
            >
              {item?.is_service ? "Select Plan" : t("continue")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PlanCard;
