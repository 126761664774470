function formatNumber(number) {
  // Convert the number to a string
  let numberString = number.toString();

  // Split the number into integer and decimal parts (if any)
  let [integerPart, decimalPart] = numberString.split('.');

  // Use a regular expression to add commas every three digits in the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Recombine the integer and decimal parts (if any)
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export default formatNumber